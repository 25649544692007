import React, { useState } from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Tooltip from "rc-tooltip"
import "rc-tooltip/assets/bootstrap.css"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Layout from "./../components/layout"
import SEO from "./../components/seo"

//import components
import GetStartedBanner from "./../components/get-started-footer"
// import icons
import CheckCircle from "./../assets/icons/svg/check-circle.svg"
// import home page
import "./../styles/pricing-page.css"

const PricingPlans = () => {
  const [periodType, setPeriodType] = useState("monthly")

  const handleChange__editPeriodType = e => {
    const value = e.target.value

    setPeriodType(value)
  }

  const pricing_plans = [
    {
      alias: "basic",
      name: "Basic",
      monthly_price: 12,
      yearly_price: 120,
      description: "Get started with powerful API and website monitoring",
    },
    {
      alias: "pro",
      name: "Pro",
      monthly_price: 29,
      yearly_price: 290,
      description: "Everything. Enough to power any young company",
    },
    {
      alias: "advanced",
      name: "Advanced",
      monthly_price: 249,
      yearly_price: 2490,
      description: "More and more for growing companies",
    },
  ]

  /* const pricing_features = [
		{
			name : "Monitors",
			plans : {
				basic : 10 ,
				pro : 50,
				advanced : 300,
			} ,
			tooltip : "A monitor is a what periodically checks your websites or APIs."	
		},
		{
			name : "Check interval",
			plans : {
				basic : '1 min',
				pro : '1 min',
				advanced : '1 min',
			},
			tooltip: "How often we run your check"
		},
		{
			name : "Data center locations",
			plans : {
				basic : 17 ,
				pro : 17,
				advanced : 17,
			},
			// tooltip : "Choose up to eight simultaneous check locations for API checks"
		},
		{
			name : "Custom locations",
			plans : {
				basic : true ,
				pro : true ,
				advanced : true,
			},
			tooltip : "Select where you want your tests to be run from"
		},
		{
			name : "SSL Monitoring",
			plans : {
				basic : true ,
				pro : true ,
				advanced : true ,
			}
		},
		{
			name : "Status Pages",
			plans : {
				basic : 1,
				pro : -1,
				advanced : -1,
			}
		},
		{
			name : "(HTTPS) Custom Domain",
			plans : {
				basic : true,
				pro : true,
				advanced : true,
			}
		},
		{
			name : "Team members",
			plans : {
				basic : 1 , 
				pro : 10 , 
				advanced : -1 , 
			}
		},
		{
			name : "Email, Slack & webhooks",
			plans : {
				basic : true,
				pro : true,
				advanced : true,
			}
		},
		{
			name : "Opsgenie, PagerDuty",
			plans : {
				basic : true,
				pro : true,
				advanced : true,
			}
		},
		{
			name : "Telegram, Discord",
			plans : {
				basic : true,
				pro : true,
				advanced : true,
			}
		},
		{
			name : "Threshold based alerting",
			plans : {
				basic : true,
				pro : true,
				advanced : true,
			},
			tooltip : "Configure when and how often your are alerted and how many reminders you should get"
		},
		{
			name : "SMS credits",
			plans : {
				basic : 50 ,
				pro : 60,
				advanced : 300,
			},
		},
		// {
		// 	name : "Weekly Report",
		// 	plans : {
		// 		basic : true,
		// 		pro : true,
		// 		advanced : true,
		// 	}
		// },
		// {
		// 	name : "Support priority via chat",
		// 	plans : {
		// 		basic : false  ,
		// 		pro : true,
		// 		advanced : 10,
		// 	}
		// },
		{
			name : "Incident Management",
			plans : {
				basic : false,
				pro : true,
				advanced : true,
			}
		},
		{
			name : "Status page subscribers",
			plans : {
				basic : false,
				pro : 1000,
				advanced : 5000,
			}
		},
		{
			name : "Manual incident notifications",
			plans : {
				basic : false,
				pro : true,
				advanced : true,
			}
		},
		{
			name : "Realtime outage notifications",
			plans : {
				basic : false,
				pro : false,
				advanced : true,
			}
		},
	]; */
  const pricing_features = [
    {
      title: "Uptime",
      items: [
        {
          name: "Monitors",
          plans: {
            basic: 10,
            pro: 40,
            advanced: 300,
          },
          tooltip:
            "A monitor is a what periodically checks your websites or APIs.",
        },
        {
          name: "Check interval",
          plans: {
            basic: "1 min",
            pro: "1 min",
            advanced: "1 min",
          },
          tooltip: "How often we run your check",
        },
        {
          name: "Data center locations",
          plans: {
            basic: 17,
            pro: 17,
            advanced: 17,
          },
          // tooltip : "Choose up to eight simultaneous check locations for API checks"
        },
        {
          name: "Custom locations",
          plans: {
            basic: true,
            pro: true,
            advanced: true,
          },
          tooltip: "Select where you want your tests to be run from",
        },
        {
          name: "SMS credits",
          plans: {
            basic: 50,
            pro: 60,
            advanced: 300,
          },
          tooltip: "Refilled every month",
        },
      ],
    },
    {
      title: "SSL Monitoring",
      items: [
        {
          name: "Monitors",
          plans: {
            basic: -1,
            pro: -1,
            advanced: -1,
          },
          tooltip: "How many SSL tests you can set up",
        },
        {
          name: "Check Interval",
          plans: {
            basic: "24 Hours",
            pro: "24 Hours",
            advanced: "24 Hours",
          },
          tooltip: "How often we run your check",
        },
        {
          name: "Custom expiration alerts",
          plans: {
            basic: true,
            pro: true,
            advanced: true,
          },
          tooltip:
            "We'll alert you when your SSL certificate is about to expire within 3, 4, 10, 30 days or within a custom number of days ",
        },
      ],
    },
    {
      title: "Status Pages",
      items: [
        {
          name: "Status Pages",
          plans: {
            basic: 1,
            pro: -1,
            advanced: -1,
          },
          tooltip:
            "Beautifully simple status page to share with your customers the uptime of your chosen monitors, incident updates and maintenance updates in real time",
        },
        {
          name: "(HTTPS) Custom Domain",
          plans: {
            basic: true,
            pro: true,
            advanced: true,
          },
        },
        {
          name: "Subscribers Notifications",
          plans: {
            basic: false,
            pro: true,
            advanced: true,
          },
          tooltip:
            "Send notifications to your status page subscribers everytime you publish updates about incidents or a maintenance.",
        },
        {
          name: "Status page subscribers",
          plans: {
            basic: 0,
            pro: 1000,
            advanced: 5000,
          },
          tooltip:
            "A subscriber is a visitor of your status page that subscribes for notifications ( incidents, scheduled maintenance or even realtime outage).",
        },
      ],
    },
    {
      title: "Incident Management",
      items: [
        {
          name: "Incidents",
          plans: {
            basic: false,
            pro: true,
            advanced: true,
          },
        },
        {
          name: "Scheduled Maintenance",
          plans: {
            basic: false,
            pro: true,
            advanced: true,
          },
        },
        {
          name: "Manual incident notifications",
          plans: {
            basic: false,
            pro: true,
            advanced: true,
          },
          tooltip:
            "Manual incidents, scheduled maintenance and all their updates will be sent to status page subscribers and your team members",
        },
        {
          name: "Realtime outage notifications",
          plans: {
            basic: false,
            pro: false,
            advanced: true,
          },
          tooltip:
            "All automated failures and recovery alerts from your monitors will be sent to subscribers on your status page",
        },
      ],
    },
    {
      title: "Alerts & Integrations",
      items: [
        {
          name: "Unlimited alert channels",
          plans: {
            basic: true,
            pro: true,
            advanced: true,
          },
          tooltip:
            "You are allowed to add multiple alert channels for each type like Email, SMS, Slack & webhooks,Telegram, Discord,Opsgenie or PagerDuty",
        },
        {
          name: "Email, SMS, Slack & webhooks",
          plans: {
            basic: true,
            pro: true,
            advanced: true,
          },
        },
        {
          name: "Twilio SMS",
          plans: {
            basic: false,
            pro: true,
            advanced: true,
          },
          tooltip:
            "You can send SMS notifications to your status page subscribers through your Twilio account, and Twilio will bill you directly for SMS notifications.",
        },
        {
          name: "Vonage(Nexmo) SMS",
          plans: {
            basic: false,
            pro: true,
            advanced: true,
          },
          tooltip:
            "You can send SMS notifications to your status page subscribers through your Vonage(Nexmo) account, and Vonage will bill you directly for SMS notifications.",
        },
        {
          name: "Opsgenie, PagerDuty",
          plans: {
            basic: true,
            pro: true,
            advanced: true,
          },
        },
        {
          name: "Telegram, Discord",
          plans: {
            basic: true,
            pro: true,
            advanced: true,
          },
        },
        /* {
					name : "Threshold based alerting",
					plans : {
						basic : true,
						pro : true,
						advanced : true,
					},
					tooltip : "Configure when and how often your are alerted and how many reminders you should get"
				}, */
      ],
    },
  ]

  return (
    <>
      <div className="pricing-wrapper">
        <div className="container">
          <h1>Simple, fair pricing</h1>
          <div className="free-trial__banner">
            <h2>Start your free 14-day trial today!</h2>
          </div>
          <div className="pricing-period-picker">
            <label>
              <input
                className="type-monthly checked"
                type="radio"
                name="payment-type"
                value="monthly"
                checked={periodType === "monthly" ? true : false}
                onChange={handleChange__editPeriodType}
              />
              Monthly
            </label>
            <label>
              <input
                className="type-yearly"
                type="radio"
                name="payment-type"
                value="yearly"
                checked={periodType === "yearly" ? true : false}
                onChange={handleChange__editPeriodType}
              />
              Yearly
            </label>
            <span className="months-free"> 2 months free</span>
          </div>
          <div className="pricing-plans">
            <div className="pt-cols-side">
              <div
                className={`pt-list-block ${
                  periodType === "yearly" ? "yearly-plan" : ""
                }`}
              >
                {pricing_features.map(featureGroup => {
                  return (
                    <>
                      <div className="pt-list-item list-group-title">
                        {featureGroup.title}
                      </div>
                      {featureGroup.items.map(feature => {
                        return (
                          <div className={`pt-list-item`}>
                            {feature.name}
                            {typeof feature.tooltip !== "undefined" &&
                              feature.tooltip !== "" && (
                                <Tooltip
                                  placement="right"
                                  trigger={["hover"]}
                                  overlay={<span>{feature.tooltip}</span>}
                                >
                                  <span className="q-item">?</span>
                                </Tooltip>
                              )}
                          </div>
                        )
                      })}
                    </>
                  )
                })}
              </div>
            </div>
            <div className="pt-cols-main">
              {pricing_plans.map(planItem => {
                return (
                  <div className="pt-col">
                    <div className="pt-block">
                      <div className="pt-title text-warning">
                        {planItem.name}
                      </div>
                      
                      <div className="pt-price">
                        <div className="price-block">
                          <span className="price">
                            {periodType === "monthly"
                              ? planItem.monthly_price
                              : planItem.yearly_price}
                          </span>
                          <span>
                            {periodType === "monthly" ? "/ month" : "/ year"}
                          </span>
                        </div>
                      </div>

                      <p className="pt-subdesc">{planItem.description}</p>
                      <div className="pt-list">
                        {pricing_features.map(featureGroup => {
                          return (
                            <>
                              <div className="pt-list-item list-group-title"></div>
                              {featureGroup.items.map(feature => {
                                const item = feature.plans[planItem.alias]
                                return (
                                  <div
                                    className={`pt-list-item ${
                                      (typeof item === "boolean" &&
                                        item === false) ||
                                      item == 0
                                        ? "unavailable-item"
                                        : ""
                                    }`}
                                  >
                                    <div className="pt-text">{`${
                                      item === 0 ? "0 " : ""
                                    }${feature.name}`}</div>
                                    <div className="pt-value">
                                      {typeof item === "boolean" ? (
                                        item === true ? (
                                          <span className="ic available-feature">
                                            <CheckCircle />
                                          </span>
                                        ) : (
                                          <span className="ic unavailable-feature">
                                            -
                                          </span>
                                        )
                                      ) : item === -1 ? (
                                        "∞ Unlimited"
                                      ) : item === 0 ? (
                                        <span className="ic unavailable-feature">
                                          -
                                        </span>
                                      ) : (
                                        item
                                      )}
                                    </div>
                                  </div>
                                )
                              })}
                            </>
                          )
                        })}
                      </div>
                      <div className="btn-wrapper">
                        <OutboundLink
                          className="btn"
                          eventCategory="Launch the Odown App"
                          eventAction="Click"
                          eventLabel="Goto signup"
                          href="https://app.odown.io/signup"
                        >
                          Start free trial
                        </OutboundLink>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const FAQ = () => {
  const faqItems = [
    {
      title: "What are Monitors?",
      description:
        "Monitors are API checks that issue POST, GET, and other typical HTTP requests at API endpoints. You have complete control over the payload, headers, etc.",
    },
    {
      title: "Do all plans have the same scheduling options?",
      description: "Monitors can run every minute up to every 24 hours.",
    },
    {
      title: "Are all alerting integrations the same for all plans?",
      description:
        "All plans have SMS, email, webhook, Slack, Telegram, Discord , Opsgenie, PagerDuty integrations to alert you when a monitor fails.",
    },
    {
      title: "How does the 14-day trial work?",
      description:
        "By signing up, you get full access to all features for a period of 14 days. You do not need a credit card to get started. After 14 days, you will still have access to your account, but we will suspend the running of your monitors.",
    },
    {
      title: "Can I switch plans easily?",
      description:
        "Absolutely. You can upgrade or downgrade your plan at any moment from your account page. We will prorate the cost, so you always pay exactly what you owe: no double bookings. We will also add a proration line to your invoice.",
    },
    {
      title: "What payment types do you accept?",
      description:
        "We accept Visa, Mastercard, and American Express credit card payments. All payments are handled by Stripe.",
    },
  ]
  return (
    <section className="u-faq">
      <div className="container">
        <h2 className="u-faq__title">Pricing FAQ</h2>
        <div className="u-faq__content">
          <ul>
            {faqItems.map(item => {
              return (
                <li>
                  <h4 className="u-faq-item__title">{item.title}</h4>
                  <p className="u-faq-item__content">{item.description}</p>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </section>
  )
}

/**
 *
 * @param {*}
 */
const Pricing = ({ data }) => (
  <Layout>
    <SEO
      title="Odown's Competitive Pricing: Get Started Today"
      description="Discover Odown's simple and fair pricing with a free 14-day trial. Choose from multiple plans to suit your needs - basic or Pro. Explore our Pricing & Plans today."
      pathname={`/pricing`}
      // meta={[
      // 	{
      // 		name : `twitter:image:src`,
      // 		content :  data?.site?.siteMetadata.siteUrl + '/general-image-og--odown.png'
      // 	},
      // 	{
      // 		name : `og:image`,
      // 		content : data?.site?.siteMetadata.siteUrl + '/general-image-og--odown.png'
      // 	},

      // ]}
      image={{
        src: "/general-image-og--home-page.jpg",
        width: 1600,
        height: 800,
      }}
    />
    <PricingPlans />
    <FAQ />
    {/* Get started banner */}
    <GetStartedBanner />
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`
export default Pricing
